import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import './Navigation.css';

export const Navigation = () => {
  const navigate = useNavigate();

  const handleNavigation = (path, hash) => {
    navigate(path); // Navigate to the desired route first
    if (hash) {
      setTimeout(() => {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 100); // Timeout to allow page rendering
    }
  };

  

  useEffect(() => {
    const handleClickOutside = (event) => {
      const menu = document.getElementById("bs-example-navbar-collapse-1");
      const toggleButton = document.querySelector(".navbar-toggle");
      if (menu.classList.contains("in") && !menu.contains(event.target) && !toggleButton.contains(event.target)) {
        menu.classList.remove("in");
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>

          <a
            className="navbar-brand page-scroll"
            href="#page-top"
            style={{ color: "darkblue", display: "flex", margin: "0px", padding: "12px" }}
          >
            <img
              src="/img/logos/logo_wave-removebg-preview.png"
              alt="Logo"
              style={{ height: "50px", marginRight: "10px" }}
            />
          </a>
        </div>

        <div
          className="navbar-right"
          style={{
            position: "fixed",
            right: "50px",
            top: "-15px",
            fontWeight: "bold",
          }}
        >
          <p style={{ margin: "10px", fontSize: "14px", color: "black" , padding:"12px"}}>
            For Help Call:{" "}
            <a href="tel:+91265232481" style={{ color: "darkblue", textDecoration: "none" }}>
              +91265232481
            </a>{" "}
            or Email:{" "}
            <a
    href="https://mail.google.com/mail/?view=cm&fs=1&to=info@wavevisions.in"
    target="_blank"
    rel="noopener noreferrer"
    style={{ color: "darkblue", textDecoration: "none" }}
  >
    info@wavevisions.in
  </a>
          </p>
        </div>

        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
            <li>
{/*          
              <Link
                to="/"
                onClick={() => handleNavigation("/","#page-top")}
                className="page-scroll"
                style={{ fontWeight: "bold", color: "black" }}
              >  */}
              <Link
                to="/"
                onClick={() => handleNavigation("/", "#page-top")}
                className="page-scroll"
                style={{ fontWeight: "bold", color: "black"  }}
              >
              Home
              </Link>
            </li>
            <li className="dropdown">
              <a href="#services" className="page-scroll dropbtn" style={{ fontWeight: "bold", color: "black" }}>
                Products <i className="fa fa-caret-down"></i>
              </a>
              <ul className="dropdown-content">
                <li>
                  <Link to="/product1" className="page-scroll">
                    InOT® Intelligent Surgeons' Control Panel
                  </Link>
                </li>
                <li>
                  <Link to="/product2" className="page-scroll">
                  Hexa Wave® LED Operation Theatre Light
                  </Link>
                </li>
                <li>
                  <Link to="/product3" className="page-scroll">
                    Spot Wave® LED Exam Light
                  </Link>
                </li>
                <li>
                  <Link to="/product4" className="page-scroll">
                    Hexawave® UV Mobile Sterilizer
                  </Link>
                </li>
                <li>
                  <Link to="/product5" className="page-scroll">
                    Medical Oxygen Analyzer OxyData
                  </Link>
                </li>
                <li>
                  <Link to="/product6" className="page-scroll">
                    Pressdata® Medical Gas Alarm + Analyser
                  </Link>
                </li>
                <li>
                  <Link to="/product7" className="page-scroll">
                    Flowdata Medical Gas Flow Analyser
                  </Link>
                </li>
                <li>
                  <Link to="/product8" className="page-scroll">
                    Boylesdata Digital Monitor For Anaesthesia
                  </Link>
                </li>
                {/* Continue with other products */}
              </ul>
            </li>
            <li>
              <Link
                to="/"
                onClick={() => handleNavigation("/", "#portfolio")}
                className="page-scroll"
                style={{ fontWeight: "bold", color: "black" }}
              >
                Gallery
              </Link>
            </li>
            <li>
              <Link
                to="/"
                onClick={() => handleNavigation("/", "#testimonials")}
                className="page-scroll"
                style={{ fontWeight: "bold", color: "black" }}
              >
                Our Clients
              </Link>
            </li>
            <li>
              <Link
                to="/"
                onClick={() => handleNavigation("/", "#about")}
                className="page-scroll"
                style={{ fontWeight: "bold", color: "black" }}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="/"
                onClick={() => handleNavigation("/", "#contact")}
                className="page-scroll"
                style={{ fontWeight: "bold", color: "black" }}
              >
                Contact US
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
