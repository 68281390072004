import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import './index.css'
import Slider from "./components/Slider";
import ContactPage from "./components/ContactPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Product1 from "./components/Product1";
import Product2 from "./components/Product2";
import Product3 from "./components/Product3";
import Product4 from "./components/Product4";
import Product5 from "./components/Product5";
import Product6 from "./components/Product6";
import Product7 from "./components/Product7";
import Product8 from "./components/Product8";


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  return (
    <Router>
      <Navigation />
      <Routes>
        <Route path="/" element={
          <>
            <Slider />
            <Features data={landingPageData.Features} />
            <Services data={landingPageData.Services} />
            <Gallery data={landingPageData.Gallery} />
            <Testimonials data={landingPageData.Testimonials} />
            <About data={landingPageData.About} />
            <Contact data={landingPageData.Contact} />
       
          </>
        } />
        <Route path="/product1" element={<Product1 />} />
        <Route path="/product2" element={<Product2 />} />
        <Route path="/product3" element={<Product3 />} />
        <Route path="/product4" element={<Product4 />} />
        <Route path="/product5" element={<Product5 />} />
        <Route path="/product6" element={<Product6 />} />
        <Route path="/product7" element={<Product7 />} />
        <Route path="/product8" element={<Product8 />} />
        <Route path="/contact" element={<Contact/>}/>
        {/* Add other product routes here */}
      </Routes>
    </Router>
  );
};

export default App;
  
//   return (
//     <div>
//       <Navigation />
//       <Slider/>
//       {/* <Header datas={landingPageData.Header} /> */}

//        <Features data={landingPageData.Features} />

//       <Services data={landingPageData.Services} />
//       {/* <Gallery data={landingPageData.Gallery} /> */}
//       <Testimonials data={landingPageData.Testimonials} />
      
//       <About data={landingPageData.About} /> 
//       <Contact data={landingPageData.Contact} />
//       {/* <ContactPage/> */}
    
//     </div>
//   );
// };

// export default App;
