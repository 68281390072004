import React from 'react'



const product5 = () => {

  const styles = {
    container: {
      margin:"150px"
    },
    productImage: {
           margin:"10px",
      maxWidth: "500px", // Ensures the image is responsive
      height: "500px",
    },
    productFeatures: {
      marginTop: "20px",
    },
    list: {
      listStyleType: "disc", // Ensures bullet points are visible
      paddingLeft: "20px", // Adds some indentation to the list
    },
    scopeOfSupply: {
      marginTop: "20px",
    },
    trainingInfo: {
      marginTop: "20px",
      lineHeight: "1.6",
    },
    footer: {
      marginTop: "40px",
      textAlign: "center",
    },
    footerLine: {
      borderBottom: "1px solid #ddd",
      marginBottom: "20px",
    },
    footerText: {
      color: "black",
      fontWeight: "bold",
    },
  };

  return (
    <div style={styles.container}>
      <h1>Medical Oxygen Analyzer OxyData </h1>
      <img 
        style={styles.productImage} 
        src='./img/products/OxyData-P-500x500-C.png'
        alt="Hexa Wave® LED Operation Theatre Light" 
      />
          <img 
        style={styles.productImage} 
        src='./img/products/OxyData-C-500x500-C.png'
        alt="Hexa Wave® LED Operation Theatre Light" 
      />
      <p>Detailed information about Spot Wave® LED Exam Light...</p>
      <a href='./catalog/Hexa Wave® LED Operation Theatre Light.pdf' download className="product-button">Download Catalog</a>
      <div style={styles.productFeatures}>
        <h2>Product Features</h2>
        <ul style={styles.list}>
          <li>This text defines Product in details... Demo text</li>
          <li>This text defines Product in details... Demo text</li>
          <li>This text defines Product in details... Demo text</li>
          <li>This text defines Product in details... Demo text</li>
          <li>This text defines Product in details... Demo text</li>
          <li>This text defines Product in details... Demo text</li>
        </ul>
      </div>
      
      <div style={styles.scopeOfSupply}>
        <h2>Scope Of Supply Includes</h2>
        <ul style={styles.list}>
          <li>This text defines Product in details... Demo text</li>
          <li>This text defines Product in details... Demo text</li>
          <li>This text defines Product in details... Demo text</li>
          <li>This text defines Product in details... Demo text</li>
          <li>This text defines Product in details... Demo text</li>
          <li>This text defines Product in details... Demo text</li>
        </ul>
      </div>
      
      <p style={styles.trainingInfo}>
        Training will be provided at Vadodara office for your installation engineers. Installation manual with easy-to-understand procedures will be provided.
        For any customization/additional feature/change/modification – wherever possible, mutual decision will be taken.
      </p>

      <div style={styles.footer}>
        <div style={styles.footerLine}></div> 
        <div className="container text-center">
          <h4 style={styles.footerText}>
            <p>&copy; 2024 Wave Visions. All Rights Reserved.</p>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default product5;

