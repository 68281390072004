import React from 'react';
import { useEffect } from 'react';
import './Product1.css'; // Linking to Product1 CSS file

const Product7 = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="product-container">
      <h1>Flowdata Medical Gas Flow Analyser</h1>
      <img 
        className="product-image" 
        src='./img/products/Flowdata.png'
        alt="Flowdata Medical Gas Flow Analyser Image-1 " 
      />
      {/* <img 
        className="product-image" 
        src='./img/products/OxyData-C-500x500.png'
        alt="Flowdata Medical Gas Flow Analyser Image-2" 
      /> */}

< h3 className="product-sub-title">An IoT based Tablet Operated Wireless Surgeon’s OT Control Panel for
      Monitoring + Control + Data storage + Data Analysis + Report Generation </h3>
      <p className="product-description"  style={{fontWeight:"bold"}}>
      Flowdata Medical Gas Flow Analyser is available with the following Specifications & Features:
        </p>
        <p className="product-description"  style={{fontWeight:"bold"}}>
          Application:
          </p>
          <ul className= "product-description">
            <li>Medical Gas Pipeline Flow measurement</li>
            <li>Hospital Gas Consumption monitoring</li>
            <li>Each Floor for Gas leakage / choke up monitoring</li>
            <li>Critical places – OT, ICU, Ward, etc…</li>
            <li>  </li>
            </ul>
            <p className="product-description"  style={{fontWeight:"bold"}}>
Flow Rate & Totaliser: </p>


<ul className= "product-description">
<li>Instantaneous Flow rate measurement</li>
<li>Flow Totaliser (Gas Consumption) for user set time</li>
</ul>

<p className="product-description"  style={{fontWeight:"bold"}}>Flow Rate Range:</p>
<ul className= "product-description">
  <li>0-100 LPM or 0-200 LPM or 0-500 LPM or 0-700 LPM</li>
</ul>
<p className="product-description"  style={{fontWeight:"bold"}}>Placement:</p>
<ul className= "product-description">
  <li>Can be used as Wall mount unit or Table-top unit</li>
</ul>
<h3 className="product-sub-title">Flowdata Medical Gas Flow Analyser</h3>
      <a href='./catalog/FlowData Gas Flow Analyser catalog.pdf' download className="product-button">
        Download Catalog
      </a>
      {/* YouTube Embed */}
<div className="product-video-container">
  <iframe
    width="70%"
    height="70%"
    src="https://www.youtube.com/embed/nLEeYZn5O3E"
    title="Wave Visions YouTube Video"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
</div>
<div className="product-features">
  <h2>Product Features</h2>
  <ul className="product-list">
    <li>Compact, Light Weight, Sleek unit</li>
    <li>Wall mountable as well as Table-top unit</li>
    <li>Standard input gas connections</li>
    <li>Built-in battery, charger and switch over circuit</li>
    <li>Big Touch screen LCD display control panel</li>
    <li>High & Low Alarm setting – user adjustable – Audio and Video alarms</li>
    <li>Real Date-Time display with Room Temperature and Humidity measurement</li>
    <li>Wi-Fi Enabled Control panel for Mobile connectivity for wireless Monitoring + Alarm</li>
  </ul>
</div>

      
<div className="product-specifications">
  <h2>Specifications</h2>
  <ul className="product-description">
    <li>Multiple Flow rate range options available: 0-100 / 200 / 500 / 700 LPM with 1 LPM resolution</li>
    <li>Flow Totaliser: 0-999,999 Liters over a user set time period of 0-999 Hours</li>
    <li>Flow Totaliser: 0-999,999 K Liters over the unit lifetime</li>
    <li>Room Temperature: 0-50°C with 1°C resolution</li>
    <li>Room Humidity: 0-100% with 1% resolution</li>
    <li>Battery backup time after full charge: 4-6 hours</li>
    <li>Wide Input voltage range: 90v to 260v to cover mains voltage fluctuations</li>
  </ul>
</div>

      
<p className="training-info">
        Training will be provided at Vadodara office for your installation engineers. Installation manual with easy-to-understand procedures will be provided.
        For any customization/additional feature/change/modification – wherever possible, mutual decision will be taken.
      </p>

      <div id="footer">
        <div className="footer-line"></div> 
        <div className="container text-center">
          <h4 style={{ color: "black" , fontWeight:"bold"}}>
            <p>&copy; 2024 Wave Visions. All Rights Reserved.</p>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Product7;
