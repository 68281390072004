import React from "react";

export const Features = () => {
  // Inline styles for various elements
  const styles = {
    container: {
      textAlign: "center",
      padding: "20px",
      maxWidth: "1200px",
      margin: "0 auto",
    },
    title: {
      color: "darkblue",
      padding: "20px 0",
      marginBottom: "30px",
      fontSize: "32px",
      fontWeight: "bold",
    },
    row: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      gap: "20px",
      marginBottom: "40px",
    },
    featureBox: {
      backgroundColor: "#f8f8f8",
      padding: "20px",
      borderRadius: "10px",
      textAlign: "center",
      maxWidth: "300px",
      flex: "1 1 calc(25% - 20px)", // Responsive grid-like layout
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      transition: "transform 0.3s ease-in-out",
      marginBottom: "20px",
    },
    featureLogo: {
      width: "70px",
      height: "70px",
      objectFit: "contain",
      marginBottom: "10px",
    },
    featureTitle: {
      color: "black",
      fontSize: "20px",
      fontWeight: "bold",
      marginBottom: "10px",
    },
    featureText: {
      color: "black",
      fontSize: "14px",
    },
    sectionTitle: {
      color: "darkblue",
      fontSize: "24px",
      fontWeight: "bold",
      margin: "30px 0",
    },
    sectionText: {
      color: "black",
      lineHeight: "1.6",
      textAlign: "left",
      maxWidth: "800px",
      margin: "0 auto",
    },
    // Media Queries
    "@media (max-width: 1200px)": {
      container: {
        padding: "20px",
      },
      featureBox: {
        flex: "1 1 calc(33.333% - 20px)", // 3 items per row
      },
    },
    "@media (max-width: 992px)": {
      title: {
        fontSize: "28px",
      },
      featureBox: {
        flex: "1 1 calc(50% - 20px)", // 2 items per row
      },
    },
    "@media (max-width: 768px)": {
      title: {
        fontSize: "24px",
      },
      featureBox: {
        flex: "1 1 calc(100% - 20px)", // 1 item per row
      },
      featureLogo: {
        width: "60px",
        height: "60px",
      },
      featureTitle: {
        fontSize: "18px",
      },
      featureText: {
        fontSize: "12px",
      },
      sectionTitle: {
        fontSize: "20px",
      },
    },
  };

  return (
    <div id="features" style={styles.container}>
      <div style={styles.title}>What We Are</div>
      <div style={styles.row}>
        <div style={styles.featureBox}>
          <img
            src="/img/logos/Webp.net-resizeimage (2).png"
            alt="Certified Product"
            style={styles.featureLogo}
          />
          <h3 style={styles.featureTitle}>Certified Product</h3>
          <p style={styles.featureText}>
            An ISO 13485:2003 and ISO 9001:2008 certified manufacturer of CE
            marked unit for Medical Equipment Manufacturing.
          </p>
        </div>
        <div style={styles.featureBox}>
          <img
            src="/img/logos/logo_exper1.png"
            alt="Our Expertise"
            style={styles.featureLogo}
          />
          <h3 style={styles.featureTitle}>Our Expertise</h3>
          <p style={styles.featureText}>
            Having rich experience since 1998 in innovative Medical Equipment
            Design, Development, and Manufacturing.
          </p>
        </div>
        <div style={styles.featureBox}>
          <img
            src="/img/logos/logo_tech.png"
            alt="Technical Analysis"
            style={styles.featureLogo}
          />
          <h3 style={styles.featureTitle}>Technical Analysis</h3>
          <p style={styles.featureText}>
            Strong Technical Background, Good Knowledge of actual requirements
            needed by Medical fraternities. Dedicated manpower, ready to support
            the customer.
          </p>
        </div>
        <div style={styles.featureBox}>
          <img
            src="/img/logos/Webp.net-resizeimage (1).png"
            alt="25+ Years of Expertise"
            style={styles.featureLogo}
          />
          <h3 style={styles.featureTitle}>25+ Years of Expertise</h3>
          <p style={styles.featureText}>
            With 25+ years in medical LED lighting, we specialize in reliable,
            energy-efficient OT lights that enhance surgical precision and
            safety.
          </p>
        </div>
      </div>

      {/* Our Infrastructure Section */}
      <div>
        <h3 style={styles.sectionTitle}>Our Infrastructure</h3>
        <p style={styles.sectionText}>
          Wave Visions has infrastructure capable of design, development, and
          manufacturing of Electro-Mechanical Medical Equipment. Wave Visions is
          equipped with all in-house facilities required for Electronic Design,
          Development and Prototyping, Manufacturing with in-house electronic
          and electrical assembly, Mechanical workshop for light industrial
          capacity Machining Center and Fabrication, Testing and Calibration
          tools for Quality Control, etc… The infrastructure has the potential
          to expand its current capacity to 3 to 4 folds.
        </p>
        <p style={styles.sectionText}>
          Being located in the Industrial area on the main Delhi-Mumbai
          Industrial Corridor, easy access to skilled manpower and other
          supports is also available.
        </p>
        <p style={styles.sectionText}>
          Wave Visions is also an OEM supplier to many leading companies,
          supplying products or modules under their brand names. Being an R & D
          oriented unit, Wave Visions is also capable of designing and
          developing similar other products.
        </p>
      </div>
    </div>
  );
};
