import React, { useEffect } from 'react';
import './Product3.css'; // Import the external CSS file

const Product3 = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="product-container">
      <h1 className="product-title">Spot Wave<span class="trademark">®</span> LED Exam Light</h1>

      <img
        src='./img/products/Spotwave-1-300x225.png'
        alt="Spot Wave® LED Exam Light"
        className="product-image"
      />

      <h3 className="product-sub-title">
        With IoT based Digital Touch Control Panel for Mobile connectivity for Monitoring + Control + Data storage + Analysis + Report Generation
      </h3>
      <p className="product-description" style={{color:"black", fontWeight:"bold"}}>Applications: </p>
      
      <ul className="product-description">
      <li>
      - For all Minor OT Surgeries, OPD, Trauma, Gynec, Examination Room, etc…
      </li>
      <li>
      - Intensity per Dome: 32,000 Lux at 1 meter / 50,000 Lux at 50 cm (0.5 m)
      </li>
      <li>
      - Mounting: Ceiling, Wall and Portable / Mobile / on Wheel
      </li>
      <li>
      - Options: Wireless Camera, Battery Backup, Monitor Arm
      </li>
      </ul>
    
      <a href='./catalog/SpotWave+ LED Exam Lights (1).pdf' download className="product-button">Download Catalog</a>
    

      <div className="section-container">
        <h2>Product Features</h2>
        <ul className="product-description">
          <li>Pure white light similar to natural daylight, offering bright and real cool light.</li>
          <li>Compact and lightweight design.</li>
          <li>For the portable unit: Four motion options to achieve all positions and angles.</li>
          <li>Low energy consumption with high efficiency and minimal heating.</li>
          <li>Large touch screen LCD display control panel.</li>
          <li>Wi-Fi enabled control panel for mobile connectivity, including wireless monitoring, control, data storage, data analysis, and report generation.</li>
          <li>The entire SpotWave mobile unit is designed for easy assembly and disassembly by non-technical users in less than minutes without the need for external tools.</li>
        </ul>
      </div>
      <div className="section-container">
        <h2>Specifications:</h2>
        <ul className="product-description">
          <li>Long life of LED for more than 50,000 hours.</li>
          <li>User selectable intensity variation from 0 - 100%.</li>
          <li>Focus diameter of 150 mm.</li>
          <li>Color temperature of the LED equal to daylight – 5,000 K to 5,500 K.</li>
          <li>Wide operating voltage range from 90 V to 260 V to cover mains voltage fluctuations.</li>
        </ul>
      </div>

      <p className="training-info">
        Training will be provided at Vadodara office for your installation engineers. Installation manual with easy-to-understand procedures will be provided.
        For any customization/additional feature/change/modification – wherever possible, mutual decision will be taken.
      </p>
      <div id="footer">
        <div className="footer-line"></div> 
        <div className="container text-center">
          <h4 style={{ color: "black" , fontWeight:"bold" }}>
            <p>&copy; 2024 Wave Visions. All Rights Reserved.</p>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Product3;
