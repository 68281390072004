import React, { useState, useEffect } from "react";
import "./Testimonials.css";

export const Testimonials = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  // Define JSON data within the component
  const testimonials = [
    {
      img: "/img/testimonials/bhailal_amin_general_hospital_logo.jfif",
      name: "Bhailal Amin General Hospital - MULTISPECIALITY"
    },
    {
      img: "/img/testimonials/MAVJAT HOSPITAL - MULTISPECIALITY.jpg",
      name: "MAVJAT HOSPITAL - MULTISPECIALITY"
    },
    {
      img: "/img/testimonials/DHIRAJ HOSPITAL - MULTISPECIALITY.jfif",
      name: "DHIRAJ HOSPITAL - MULTISPECIALITY"
    },
    {
      img: "/img/testimonials/NARHARI GENERAL HOSPITAL - MULTISPECIALITY.png",
      text: "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.\"",
      name: "NARHARI GENERAL HOSPITAL - MULTISPECIALITY"
    },
    {
      img: "/img/testimonials/hospitls.jpg",
      name: "YASHODHARA HOSPITAL - MULTISPECIALITY"
    },
    {
      img: "/img/testimonials/cloudnine-hospital-horamavu-bengaluru-logo.png",
      name: "CLOUD NINE HOSPITAL - MULTISPECIALITY"
    },
    {
      img: "/img/testimonials/SATYAM HOSPITAL - MULTISPECIALITY.jfif",
      name: "SATYAM HOSPITAL - MULTISPECIALITY"
    },
    {
      img: "/img/testimonials/SHREEJI HOSPITAL - MULTISPECIALITY.jpg",
      name: "SHREEJI HOSPITAL - MULTISPECIALITY"
    },
    {
      img: "/img/testimonials/YASH ORTHOPEDIC HOSPITAL.jpg",
      name: "YASH ORTHOPEDIC HOSPITAL"
    },
    {
      img: "/img/testimonials/ASHUTOSH ORTHOPEDIC HOSPITAL.jpg",
      name: "ASHUTOSH ORTHOPEDIC HOSPITAL"
    },
    {
      img: "/img/testimonials/VIROC ORTHOPEDIC HOSPITAL.png",
      name: "VIROC ORTHOPEDIC HOSPITAL"
    },
    {
      img: "/img/testimonials/hospitls.jpg",
      name: "SHREE SIDHIVINAYAK HEART HOSPITAL"
    },
    {
      img: "/img/testimonials/RHYTHM HEART INSUTITUTE.png",
      name: "RHYTHM HEART INSUTITUTE"
    },
    {
      img: "/img/testimonials/hospitls2.png",
      name: "SHRUSHTRIL HOSPITAL - NEUROSURGERY"
    },
    {
      img: "/img/testimonials/VINS HOSPITAL - NEUROSURGERY.png",
      name: "VINS HOSPITAL - NEUROSURGERY"
    },
    {
      img: "/img/testimonials/cloudnine-hospital-horamavu-bengaluru-logo.png",
      name: "CLOUD NINE HOSPITAL - MULTISPECIALITY"
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % Math.ceil(testimonials.length / 4));
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [testimonials.length]);

  const handlePrev = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + Math.ceil(testimonials.length / 4)) % Math.ceil(testimonials.length / 4));
  };

  const handleNext = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % Math.ceil(testimonials.length / 4));
  };

  const getVisibleTestimonials = () => {
    const startIndex = currentSlide * 4;
    return testimonials.slice(startIndex, startIndex + 4);
  };

  return (
    <div id="testimonials">
      <div className="container" >
        <div className="section-title text-center">
          <h2 style={{ margin: "40px" }}>Our Clients</h2>
        </div>
        <div className="testimonial-slider">
          <div className="testimonial-list">
            {getVisibleTestimonials().map((d, i) => (
              <div key={`${d.name}-${i}`} className="testimonial-item">
                <div className="testimonial-content">
                  <div className="testimonial-meta">
                    <img src={d.img} alt={d.name} className="testimonial-logo" />
                    <h4>{d.name}</h4>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <button className="slider-control prev" onClick={handlePrev}>
            &#9664; {/* Left arrow */}
          </button>
          <button className="slider-control next" onClick={handleNext}>
            &#9654; {/* Right arrow */}
          </button>
        </div>
      </div>
    </div>
  );
};
