import React, { useState } from 'react';
import './Gallery.css'; // Import the CSS for styling

// The Image component handles the full-screen view
const Image = ({ title, smallImage, largeImage, alt }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleClick = () => {
    setIsFullScreen(!isFullScreen);
  };

  return (
    <>
      <div className="portfolio-item" onClick={handleClick}>
        <img src={smallImage} alt={alt} className="small-image" />
        <div className="small-image-title" style={{fontWeight:"bold"}}>{title}</div> {/* Display Title Below Image */}
      </div>
      {isFullScreen && (
        <div className="full-screen-overlay" onClick={handleClick}>
          <div className="full-screen-title">{title}</div>
          <img src={largeImage} alt={alt} className="large-image" />
        </div>
      )}
    </>
  );
};

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title" style={{margin:"5px"}}>
          <h2>Gallery of Product Images</h2>
          <p style={{ color: "black", fontWeight:"bold" }}>
            A gallery showcasing our products, including LED OT lights, intelligent control panels, and medical gas analyzers, displayed for a streamlined view.
          </p>
          <p style={{fontWeight:"bold"}}>Click the Image to View Full Screen</p>
        </div>
        <div className="portfolio-items"> {/* Remove Bootstrap row class */}
          {props.data
            ? props.data.map((d, i) => (
                <div
                  key={`${d.title}-${i}`}
                  className="portfolio-item-wrapper" // Keep the custom class only
                >
                  <Image
                    title={d.title}
                    smallImage={d.smallImage}
                    largeImage={d.largeImage}
                    alt={d.title}
                  />
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
