import React, { useEffect } from 'react';
import './Product1.css';

const Product1 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="product-container">
      <h1 className="product-title">InOT Surgeons' Control Panel</h1>
      
      <img
        src="./img/products/InOT image2.png"
        alt="InOT Surgeons' Control Panel"

      />

      <h3 className="product-sub-title">
        An IoT based Tablet Operated Wireless Surgeon’s OT Control Panel for Monitoring + Control + Data storage + Data Analysis + Report Generation
      </h3>
      
      <ul className="product-description">
        <li>- Screen size: 8 inch touch screen Tablet</li>
        <li>- Android Operating system</li>
        <li>- Working on Mains Supply: 230V, 50 Hz</li>
      </ul>

      <h4 className="product-description" style={{ fontWeight: 'bold' }}>
        Reports that can be generated from InOT app on Tablet:
      </h4>
      <ul className="product-description">
        <li>- Individual Operative procedure report</li>
        <li>- Day wise activity report</li>
        <li>- Patient wise procedure report</li>
        <li>- Each Gas pressure & Vacuum report</li>
        <li>- Temperature & Humidity report</li>
        <li>- Surgeon & Anaesthesiologist day wise report & more…</li>
      </ul>

      <a
        href="./catalog/InOT Surgeons OT Control Panel by Wave Visions.pdf"
        download
        className="product-button"
      >
        Download Catalog
      </a>

      <div className="product-video-container">
        <iframe
          width="70%"
          height="400px"
          src="https://www.youtube.com/embed/nLEeYZn5O3E"
          title="Wave Visions YouTube Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <div className="section-container">
        <h2>Product Features</h2>
        <ul>
          <li>Time Monitoring and Control with Audio-Visual Alarm for Anaesthesia Time, Surgery Time, etc.</li>
          <li>Five Pressures monitoring: Oxygen, CO2, N2O, AIR with alarms</li>
          <li>Vacuum Pressure monitoring with alarms</li>
          <li>Room Temperature and Humidity monitoring with alarms</li>
          <li>Wireless Switching control for 12 devices (OT Lights, X-ray Viewer, Air conditioning, etc.)</li>
          <li>Real-time data reports</li>
          *Customised options also available
        </ul>
      </div>

      <div className="section-container">
        <h2>Scope Of Supply Includes</h2>
        <ul>
          <li>10 inch Tablet with pre-installed InOT application</li>
          <li>Wireless PressData unit with sensors for gas, temperature, humidity</li>
          <li>Wireless switch modules for 12 devices (25 Amp capacity)</li>
          <li>Router for local network connectivity</li>
        </ul>
      </div>

      <p className="training-info">
        Training will be provided at Vadodara office for your installation engineers. Installation manual with easy-to-understand procedures will be provided. Customization requests are open to discussion.
      </p>

      <div id="footer">
        <div className="footer-line"></div>
        <p>&copy; 2024 Wave Visions. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default Product1;
