import React from 'react';
import './Product4.css'; // Import the external CSS file
import { useEffect } from 'react';

const Product4 = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="product-container">
      <h1 className="product-title">Hexa Wave<span class="trademark">®</span> UV Mobile Sterilizer for OT, ICU</h1>
      <img 
        // className="product-image1" 
        src='./img/products/HexaWave_Mobile_UVC_Room_Steriliser1_Photo-removebg-preview.png'
        alt="Hexa Wave® LED Operation Theatre Light" 
      />
      <img 
        // className="product-image2" 
        src='./img/products/wifimobile.png'
        alt="wifimobile" 
      />
      <h3 className="product-sub-title">With High UV Power & 360o safety features</h3>
      {/* <p className="product-description bold"  style={{fontWeight:"bold"}}>
        UV Mobile Sterilizer for OT, ICU is available with the following Specifications & Features:
      </p> */}
  
  <div className="product-description-container">
        <p className="product-description" style={{color:"black", fontWeight:"bold"}}>Applications: </p>
          <ul className="product-description" >
      <li>- For Quick Air & Surface sterilization of : OT, ICU, Rooms between patient changeover</li>
      <li>- Area with high probability of Infection spread like</li>
      <li>- Reception, Waiting area, OPD, Exam room</li>
 <li>-  Clean Rooms</li>
      
</ul>
</div>
      <a href='./catalog/HexaWave UV Mobile Sterilizer catalog.pdf' download className="product-button">Download Catalog</a>

      <div className="section-container">
        <h2>Product Features</h2>
        <ul className="product-description">
          <li>Only 12-15 minutes required to sterilize a room size of 20 ft * 20 ft</li>
          <li>6 feet height to cover most of the human usable area</li>
          <li>Sufficient high power to reach the maximum area even after reflections</li>
          <li>With all-around 360° movement detection for human safety</li>
          <li>Very simple and quick user interface</li>
          <li>Can be operated from the unit without mobile/tablet</li>
          <li>Android Mobile App via Bluetooth for live monitoring, control, data storage, analysis, and report generation from your mobile</li>
        </ul>
      </div>


      <div className="section-container">
        <h2>Specifications:</h2>
        <ul className="product-description">
          <li>UV-C at 254 nm - the most germicidal effective wavelength</li>
          <li>Total 650 watts UV-C power</li>
          <li>Display: 3.5-inch color touch screen</li>
          <li>Wi-Fi (IoT) enabled</li>
          <li>Dimensions: 6 feet height * 600 mm diameter</li>
          <li>Weight: 25 Kg</li>
        </ul>
      </div>

      <p className="training-info">
        Training will be provided at Vadodara office for your installation engineers. Installation manual with easy-to-understand procedures will be provided.
        For any customization/additional feature/change/modification – wherever possible, mutual decision will be taken.
      </p>

      <div id="footer">
        <div className="footer-line"></div> 
        <div className="container text-center">
          <h4 className="footer-text">
            <p>&copy; 2024 Wave Visions. All Rights Reserved.</p>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Product4;
