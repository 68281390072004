import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate

// This is the Services page
export const Services = (props) => {
  const navigate = useNavigate(); // Initialize navigate

  // Inline styles
  const containerStyle = {
    border: '4px solid #ddd', // Border around the image, name, and button
    borderRadius: '15px',
    padding: '5px',
    marginBottom: '10px',
    textAlign: 'center'
  };

  const imageStyle = {
    width: 'auto', // Make the width 100% to fit the container width
    height: 'auto', // Set height to auto to maintain aspect ratio
    maxWidth: '330px', // Set maximum width
    maxHeight: '330px', // Set maximum height
    marginBottom: '5px',
    border: '7px solid #ddd', // Border around the image
    borderRadius: '30px',
    objectFit: 'cover' // Ensures the image covers the set dimensions while maintaining aspect ratio
  };

  const buttonStyle = {
    marginTop: '15px',
    padding: '10px 20px',
    backgroundColor: '#4CAF50', // Green background color
    border: 'none',
    borderRadius: '5px',
    color: '#fff', // White text color
    cursor: 'pointer',
    fontSize: '16px',
    textDecoration: 'none',
    display: 'inline-block',
    transition: 'background-color 0.3s' // Smooth transition for hover effect
  };

  const buttonHoverStyle = {
    backgroundColor: '#45a049' // Darker green on hover
  };

  const handleButtonClick = (productId) => {
    navigate(`/product${productId}`); // Navigate to the corresponding product page
  };

  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title" style={{ color: "black" }}>
          <h2 style={{ color: "black" }}>Our Products</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div style={{ color: "black" }} key={`${d.name}-${i}`} className="col-md-4">
                  <div style={containerStyle}>
                    <img src={d.img} alt={d.name} style={imageStyle} />
                    <h3 style={{ color: "black" }}>{d.name}</h3>
                    <button
                      style={buttonStyle}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor)
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor)
                      }
                      onClick={() => handleButtonClick(i + 1)} // Use the index to determine product ID
                    >
                      More Details
                    </button>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
