import React from "react";

export const About = (props) => {
  return (
    <div id="about" style={{color:"black"}}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <img
              src="img/AboutUS_5.png"
              className="img-responsive"
              alt="aboutus image"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-0text">
              <h2>About Us</h2>
              <p style={{ fontSize: "18px" }}>
                {props.data ? props.data.paragraph : "loading..."}
              </p>
              <h3>Why Choose Us?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`} style={{ fontSize: "15px" }}>
                            {d}
                          </li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`} style={{ fontSize: "15px" }}>
                            {d}
                          </li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="achivement" style={{ margin: "50px 0" }}>
        <h1 style={{ color: "black", textAlign: "center", fontWeight: "bold" }}>
          OUR ACHIEVEMENTS
        </h1>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "20px",
            textAlign: "center",
          }}
        >
          <div style={{ flex: "1", maxWidth: "420px" }}>
            <img
              src="\img\achivement\Wave-Award-Certificate-English-300x239.jpg"
              alt="Productivity And Innovation Award - First Prize"
              style={{
                width: "70%",
                height: "auto",
                objectFit: "contain",
                maxHeight: "200px",
              }}
            />
            <h3 style={{ fontWeight: "bold", color: "black", marginTop: "10px" }}>
              Productivity And Innovation Award - First Prize
            </h3>
          </div>
          <div style={{ flex: "1", maxWidth: "420px" }}>
            <img
              src="\img\achivement\National Innovation Award Winner (by Ministry of Industries, Govt of India).jpg"
              alt="National Innovation Award Winner"
              style={{
                width: "80%",
                height: "auto",
                objectFit: "contain",
                maxHeight: "200px",
              }}
            />
            <h3 style={{ fontWeight: "bold", color: "black", marginTop: "10px" }}>
              National Innovation Award Winner (By Ministry of Industries, Govt. of India)
            </h3>
          </div>
          <div style={{ flex: "1", maxWidth: "420px" }}>
            <img
              src="\img\achivement\National Innovation Award - Wave visions.jpg"
              alt="National Innovation Award - Wave visions"
              style={{
                width: "80%",
                height: "auto",
                objectFit: "contain",
                maxHeight: "200px",
              }}
            />
            <h3 style={{ fontWeight: "bold", color: "black", marginTop: "10px" }}>
              National Innovation Award - Wave visions
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};
