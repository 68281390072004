import React, { useState, useEffect } from 'react';
import './Slider.css';


const Slider = () => {
  const images = [
    { src: './img/products/InOT image.png', 
      description: "InOT® - Intelligent Surgeon's OT Control Panel." },
    { src: './img/products/HW+_Single_Dome_with_Yoke-removebg-preview.png', 
      description: 'Hexa Wave® - LED Operation Theatre Light.' },
    { src: './img/products/HexaWave_Mobile_UVC_Room_Steriliser1_Photo-removebg-preview.png',
       description: 'Hexa Wave® - UV Mobile Sterilizer For OT and ICU.' },
    { src: './img/products/OxyData-P-500x500-C.png', 
      description: 'OxyData - Advanced Oxygen Analysers.' },
    { src: './img/products/HW+_Single_Dome.png', description: 'Hexa Wave® - Light For Major OT.' },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, 7000);
    return () => clearInterval(interval);
  }, [images.length]);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="slider-wrapper" style={{ margin: '90px' }}>
      <p style={{ textAlign: 'center', fontSize: '22px', color: 'black', lineHeight: '1.6', padding: '10px 15px', fontFamily: 'Arial, sans-serif' }}>
        {/* One of the leading LED OT lights manufacturers in India. We are expert LED OT light manufacturer and supplier in India. Our LED operating room lights are equipped with the latest lighting technology, providing the best quality illumination and eliminating distracting shadows from surgeons' heads and hands. */}
        Wave Visions, winner of Best Innovation Award from Government of India, is an ISO 13485 certified Pioneer Designer & Manufacturer of Medical / Surgical IoT Devices for Operation Theatre, ICU and Medical Gas Pipeline.
        Wave Visions is proud to introduce LED based OT Lights for the first time in India in 2009 and also the first in India to introduce IoT Medical Devices for OT, ICU and MGPS in 2022.
      </p>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button onClick={goToPrevious} style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', color: 'white', border: 'none', fontSize: '24px', padding: '10px', cursor: 'pointer', marginRight: '10px' }}>
            &lt;
          </button>
          <div className="slider-container" style={{ textAlign: 'center' }}>
            <img src={images[currentIndex].src} alt={`Slider Image ${currentIndex + 1}`} className="slider-image" style={{ maxWidth: '600px', height: 'auto' }} />
            <p style={{ color: 'black', fontSize: '25px', fontWeight: 'bold', marginTop: '10px' }}>
              {images[currentIndex].description}
            </p>
          </div>
          <button onClick={goToNext} style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', color: 'white', border: 'none', fontSize: '24px', padding: '10px', cursor: 'pointer', marginLeft: '10px' }}>
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export default Slider;
