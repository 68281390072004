import React from 'react';
import { useEffect } from 'react';
import './Product2.css'; // Uncomment this when you have external styles in 'Product2.css'

const Product2 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="product-container">
      <h1 className="product-title">Hexa Wave<span class="trademark">®</span> LED Operation Theatre Light</h1>
      <div >
     
        <img
          src='./img/products/HW+_Single_Dome_with_Yoke-removebg-preview.png'
          alt="Hexa Wave® LED Operation Theatre Light"
          className="product-image1"
        />
        <img
          src='./img/products/HW+ Dual Dome-41.png'
          alt="Hexa Wave® LED Operation Theatre Light"
          className="product-image2"
        />
           <img
          src='./img/products/Hexa Wave® LED Operation Theatre Light.png'
          alt="Hexa Wave® LED Operation Theatre Light"
         className="product-image3"
      />
      </div>
      <h3 className="product-sub-title">
        With IoT based Digital Touch Control Panel for Mobile connectivity for Monitoring + Control + Data storage + Analysis + Report Generation
      </h3>

      <div className="product-description-container">
        <p className="product-description" style={{color:"black", fontWeight:"bold"}}>Applications: </p>
          <ul className="product-description" >
        <li>- For all Major Surgeries - Cardiac, Neuro, Ortho, Gynec, General, ENT, etc…</li>       
        <li>- Intensity per Dome: 1,00,000 Lux / 1,20,000 Lux / 1,60,000 Lux at 1 meter</li>
        <li>- Mounting: Ceiling or Wall or Portable / Mobile / on Wheels</li>
        <li>- Variations: Single Dome, Dual dome, Triple Dome</li>
        <li>- Options: Wireless Camera, Battery Backup, Monitor Arm</li>
        </ul>
      </div>

      <a href='./catalog/HexaWave+ LED OT Lights catalog.pdf' download className="product-button">Download Catalog</a>


      <div className="section-container">
        <h2>Product Features</h2>
        <ul className="product-description">
          <li>Pure White like Natural Daylight, Bright and Real Cool Light</li>
          <li>Compact, Light Weight, Slim Dome design suitable for Laminar flow</li>
          <li>Imported Spring Arm structure</li>
          <li>Six motions in each arm to achieve all positions and angles</li>
          <li>All five rotary motions in each arm with stopper less 360° design for continuous rotation</li>
          <li>More than 1100 mm up-down movement</li>
          <li>Wide reach of more than 2 meters in all directions from center for both arms to cover the majority of Operation room area</li>
          <li>Sterilisable & Detachable Handle at the Dome center for Focus adjustment</li>
          <li>Low energy consumption, High efficiency, very Low heating</li>
          <li>Big Touch screen color LCD display control panel</li>
          <li>Wi-Fi Enabled Control panel for Mobile connectivity for wireless Monitoring, Control, Data storage, Data Analysis, and Report Generation</li>
        </ul>
      </div>

      <div className="section-container">
        <h2>Specifications:</h2>
        <ul className="product-description">
          <li>Long Life of more than 50,000 hours for LED</li>
          <li>User selectable Intensity variation from 0 - 100%</li>
          <li>Color temperature of the LED equal to daylight 5,0000 K +/- 2000 K</li>
          <li>Wide Input voltage range: 90 V to 260 V to cover mains voltage fluctuations</li>
          <li>User adjustable Focus diameter of 150-300 mm & Focus distance of 700-1500 mm</li>
        </ul>
      </div>

      <p className="training-info">
        Training will be provided at Vadodara office for your installation engineers. Installation manual with easy-to-understand procedures will be provided.
        For any customization/additional feature/change/modification – wherever possible, mutual decision will be taken.
      </p>

      <div id="footer">
        <div className="footer-line"></div>
        <div className="container text-center">
          <h4 className="footer-text">
            <p>&copy; 2024 Wave Visions. All Rights Reserved.</p>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Product2;
