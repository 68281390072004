import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import data from '../data/data.json';

const initialState = {
  name: "",
  email: "",
  message: "",
  phone: "",
  city: "",
  products: [],
};

export const Contact = (props) => {
  const [{ name, email, message, phone, city, products }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setState((prevState) => ({
        ...prevState,
        products: [...prevState.products, name],
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        products: prevState.products.filter((product) => product !== name),
      }));
    }
  };
  const clearState = () => setState({ ...initialState });
  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Convert products array to a comma-separated string
    const productsString = products.join(", ");
  
    // Log the data for debugging
    console.log(name, email, message, phone, city, productsString);
  
    // Send the email
    emailjs
      .sendForm(
        "service_ach4b4i", // replace with your own Service ID
        "template_tqxqcwm", // replace with your own Template ID
        e.target,
        "G196X1By1iNJhucNu" // replace with your own Public Key
      )
      .then(
        (result) => {
          console.log(result.text);
          clearState(); // Clear form state after successful submission
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p style={{color: "black", fontSize: "20px"}}>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name*"
                        value={name} // Bind to state
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email*"
                        value={email} // Bind to state
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder="Phone*"
                        value={phone} // Bind to state
                        required
                        onChange={(e) => handleChange({
                          target: {
                            name: e.target.name,
                            value: e.target.value.replace(/[^0-9]/g, '')
                          }
                        })}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="city"
                        name="city"
                        className="form-control"
                        placeholder="City*"
                        value={city} // Bind to state
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="7"
                      placeholder="Message"
                      value={message} // Bind to state
                      required
                      onChange={handleChange}
                      style={{ width: "96%", height: "150px", marginLeft: "15px" }}
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label style={{color: "black", fontSize: "20px"}}>Select Products:</label>
                      <div style={{ display: 'flex', flexDirection: 'column', fontSize: '18px', color: "black"}}>
                        {[
                     "InOT® Intelligent Surgeons' Control Panel",
                      "Hexa Wave® LED Operation Theatre Light",
                      "Spot Wave® LED Exam Light",
                      "Hexawave® UV Mobile Sterilizer",
                      "Medical Oxygen Analyzer OxyData",
                      "Pressdata® Medical Gas Alarm + Analyser",
                      "Flowdata Medical Gas Flow Analyser",
                     "Boylesdata Digital Monitor For Anaesthesia",
                        ].map((product, index) => (
                          <label key={index} style={{ marginBottom: '10px', fontSize: "20px" }}>
                            <input
                              type="checkbox"
                              name={product}
                              checked={products.includes(product)}
                              onChange={handleCheckboxChange}
                              style={{ transform: 'scale(1.5)', marginRight: '10px' }}
                            />
                            {product}
                          </label>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg" style={{ margin: "0px", padding: "15px", color: "white", backgroundColor: "orange"}}>
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p style={{color: "black"}}>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
              <iframe
                src={`https://www.google.com/maps?q=${encodeURIComponent(props.data ? props.data.address : '')}&output=embed`}
                width="120%"
                height="320"
                style={{ border: 2 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map"
              ></iframe>
            </div>
            <div className="contact-item">
              <p style={{ color: "black" }}>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                <a href={`tel:${props.data ? props.data.phone : ''}`} style={{ color: "black" }}>
                  {props.data ? props.data.phone : "loading"}
                </a>
                {props.data && props.data.landline && (
                  <>
                    <br />
                    <a href={`tel:${props.data.landline}`} style={{ color: "black" }}>
                      {props.data.landline}
                    </a>
                  </>
                )}
              </p>
            </div>
            <div className="contact-item">
  <p style={{ color: "black" }}>
    <span>
      <i className="fa fa-envelope"></i> Email
    </span>{" "}
    {props.data && props.data.email ? (
      <a
        href={`https://mail.google.com/mail/?view=cm&fs=1&to=${props.data.email}`} // Updated to open Gmail compose
        target="_blank" // Open in a new tab
        rel="noopener noreferrer" // Security measure
        style={{ color: "darkblue", textDecoration: "none" }}
      >
        {props.data.email}
      </a>
    ) : (
      "loading"
    )}
  </p>
</div>


          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"} target="_blank" rel="noopener noreferrer">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"} target="_blank" rel="noopener noreferrer">
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"} target="_blank" rel="noopener noreferrer">
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : "/"} target="_blank" rel="noopener noreferrer">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.whatsapp : "/"} target="_blank" rel="noopener noreferrer">
                      <i className="fa fa-whatsapp"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <h4 style={{ color: "black" }}>
            <p>&copy; 2024 Wave Visions. All Rights Reserved.</p>
          </h4>
        </div>
      </div>
    </div>
  );
};
